
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { Permissions } from "@/mixins/permissions";
import PageTitle from "@/components/General/PageTitle.vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Status } from "@/models/status.interface";
import { SocialNetwork } from "@/models/social-network.interface";
import IconsPicker from "@/components/General/IconsPicker.vue";
import { StyleCheck } from "@/mixins/style-check";
import DotsMenu from "@/components/General/DotsMenu.vue";
@Component({
  components: { PageTitle, Loader, Empty, IconsPicker, DotsMenu },
})
export default class SocialNetworks extends mixins(
  FormValidations,
  Navigation,
  Permissions,
  StyleCheck
) {
  iconsUrl = process.env.VUE_APP_ICONS_URL;
  $refs!: {
    socialNetworkForm: HTMLFormElement;
  };
  loader = false;
  loading = false;
  loadingDT = false;
  dialog = false;
  dialogDelete = false;
  status = [this.$constants.STATUS.ACTIVE, this.$constants.STATUS.INACTIVE];
  defaultSocialNetwork: SocialNetwork = {
    id: "",
    icon: "",
    name: "",
    url: "",
    status: {
      name: "",
    },
  };
  socialNetwork: SocialNetwork = { ...this.defaultSocialNetwork };
  search = "";
  options = {};

  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  private getIcon(value: any) {
    if (value !== null) {
      this.socialNetwork.icon = value.name;
    }
  }

  private async searchSocialNetworksByText(clear: boolean) {
    this.loadingDT = true;
    await this.$nextTick();
    if (!this.search || clear === true) {
      this.search = "";
    }

    await this.getSocialNetworks(
      this.pagination.page,
      this.pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  @Watch("options", { deep: true })
  private async setItems(pagination) {
    this.loadingDT = true;
    this.pagination.itemsPerPage = pagination.itemsPerPage;

    await this.getSocialNetworks(
      pagination.page,
      pagination.itemsPerPage == -1
        ? this.pagination.totalItems
        : this.pagination.itemsPerPage,
      this.search
    );
    this.loadingDT = false;
  }

  private async getSocialNetworks(
    page: number,
    size: number,
    text = ""
  ): Promise<void> {
    await this.$store
      .dispatch("socialNetworks/getSocialNetworksToStaff", { page, size, text })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("SocialNetworks.fetchError.socialNetworks"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    this.loader = true;
    await this.getSocialNetworks(
      this.pagination.page,
      this.pagination.itemsPerPage,
      this.search
    );
    this.loader = false;
  }

  private get headers() {
    return [
      {
        text: this.$tc("SocialNetworks.headers.icon"),
        value: "icon",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("SocialNetworks.headers.name"),
        value: "name",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("SocialNetworks.headers.url"),
        value: "url",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("SocialNetworks.headers.status"),
        value: "status",
        align: "center",
        sortable: false,
      },
      {
        text: this.$tc("SocialNetworks.headers.actions"),
        value: "actions",
        sortable: false,
        align: "center",
      },
    ];
  }

  private get socialNetworks(): SocialNetwork[] {
    let response = this.$store.getters["socialNetworks/getSocialNetworks"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.socialNetworks) {
      return response.socialNetworks;
    } else {
      return [];
    }
  }

  private async createSocialNetwork() {
    this.loading = true;
    if (this.$refs.socialNetworkForm.validate()) {
      await this.$store
        .dispatch("socialNetworks/createSocialNetwork", {
          social_network: this.socialNetwork,
        })
        .then(async () => {
          const Success: Notification = {
            message: this.$tc("SocialNetworks.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getSocialNetworks(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("SocialNetworks.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async editSocialNetwork(socialNetwork: SocialNetwork) {
    this.socialNetwork = { ...socialNetwork };
    this.socialNetwork.status = { ...socialNetwork.status } as Status;
    this.dialog = true;
  }

  private async updateSocialNetwork() {
    this.loading = true;
    if (this.$refs.socialNetworkForm.validate()) {
      await this.$store
        .dispatch("socialNetworks/updateSocialNetwork", this.socialNetwork)
        .then(async () => {
          await this.$store.dispatch(
            "socialNetworks/updateSocialNetworkStatus",
            {
              id: this.socialNetwork.id,
              status: this.socialNetwork.status?.name,
            }
          );
          const Success: Notification = {
            message: this.$tc("SocialNetworks.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          await this.getSocialNetworks(
            this.pagination.page,
            this.pagination.itemsPerPage == -1
              ? this.pagination.totalItems
              : this.pagination.itemsPerPage,
            this.search
          );
          this.close();
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("SocialNetworks.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  private async deleteSocialNetworkConfirm(socialNetwork: SocialNetwork) {
    this.socialNetwork = { ...socialNetwork };
    this.dialogDelete = true;
  }

  private async deleteSocialNetwork() {
    this.loading = true;
    await this.$store
      .dispatch("socialNetworks/updateSocialNetworkStatus", {
        id: this.socialNetwork.id,
        status: this.$constants.STATUS.DELETED,
      })
      .then(async () => {
        const Success: Notification = {
          message: this.$tc("SocialNetworks.deleteSuccess"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        await this.getSocialNetworks(
          this.pagination.page,
          this.pagination.itemsPerPage == -1
            ? this.pagination.totalItems
            : this.pagination.itemsPerPage,
          this.search
        );
        this.closeDelete();
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("SocialNetworks.deleteError"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private closeDelete() {
    this.dialogDelete = false;
    this.socialNetwork = Object.assign(
      this.socialNetwork,
      this.defaultSocialNetwork
    );
  }

  private get formTitle() {
    return !this.socialNetwork.id
      ? this.$tc("SocialNetworks.createTitle")
      : this.$tc("SocialNetworks.editTitle");
  }

  resetValidations() {
    this.resetFormValidations([this.$refs.socialNetworkForm]);
  }

  private close() {
    this.dialog = false;
    this.socialNetwork = Object.assign(
      this.socialNetwork,
      this.defaultSocialNetwork
    );
    this.resetValidations();
  }
}
